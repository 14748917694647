.card {
    /* other styles... */
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
    height: 155px; /* adjust as needed */
    width: 105px; /* adjust as needed */
    box-sizing: border-box;
    border: 2px solid #ff00cc; /* Magenta */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 8px 0 rgba(0, 255, 255, 0.2); /* Cyan shadow */
}

    .card.isFlipped {
        transform: rotateY(180deg);
    }

.cardFront, .cardBack {
    /* other styles... */
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
}

.cardFront {
    transform: rotateY(0deg);
}

.cardBack {
    transform: rotateY(180deg);
}

.cardImage {
    width: 100px; /* adjust as needed */
    height: 150px; /* adjust as needed */
    object-fit: cover;
}

@keyframes pulseGlow {
    0%, 100% {
        border-color: green;
        box-shadow: 0 0 8px green;
    }

    50% {
        border-color: #00ff00;
        box-shadow: 0 0 15px #00ff00;
    }
}

.isMatched {
    border: 5px solid green;
    animation: pulseGlow 1s ease-out 3;
}