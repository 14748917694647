.card-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    justify-items: center;
}

.playerInfoText {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin: 10px 0;
}

.game-container {
    padding: 20px;
}

.gameTitle {
    text-align: center;
    font-size: 2.5em;
    color: #fff; /* change the color to white */
    text-shadow: 3px 3px 6px #db00ff; /* increase the size of the text shadow */
    margin-bottom: 20px;
}

@keyframes pulseGlow {
    0%, 100% {
        border-color: #02fffa;
        box-shadow: 0 0 8px #02fffa;
    }

    50% {
        border-color: #3fcdca;
        box-shadow: 0 0 15px #3fcdca;
    }
}

.restartButton {
    display: block;
    margin: 0 auto 20px;
    padding: 10px 20px;
    font-size: 1.2em;
    color: #fff;
    background-color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

    .restartButton:hover {
        background-color: #ac00ff;
        animation: pulseGlow 1s ease-out 3;
    }