@import url("https://fonts.googleapis.com/css2?family=Russo+One&family=Yellowtail&display=swap");

body {
    width: 100vw;
    height: 100vh;
    background: black;
    display: grid;
    filter: saturate(130%) contrast(125%);
    overflow: auto;
    background-image: url('../public/imgs/skyleech01.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
    body::after {
        content: "";
        background: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1; /* Ensure the overlay is behind the content */
    }

.image {
    background: #0c0c4a;
    position: relative;
    border: 8px solid;
    border-color: darkcyan cyan;
    margin-bottom: 10px;
    display: grid;
    flex-direction: column;
}

.image::before {
    content: " ";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 8px solid;
    border-color: darkmagenta magenta;
}

.top {
    height: 100%;
    width: 100%;
    background: linear-gradient(transparent 50%, hotpink 160%);
    position: absolute;
    z-index: 1;
    padding:8px;
}

.sky {
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient( pink 2px, transparent 3px, transparent 30px ), repeating-linear-gradient(90deg, pink 2px, transparent 3px, transparent 40px) 10px 0;
    filter: drop-shadow(0 0 1px magenta) drop-shadow(0 0 3px magenta) drop-shadow(0 0 7px cyan);
    animation: move 10s linear infinite;
    opacity: 0.2;
    z-index: 0;
}


.text {
    position: inherit;
    z-index: 10;
    width: 100%;
    height: 75%;
    top: 0;
    display: flex; 
    flex-direction: column;
    place-items: center;
    justify-content: center;
    text-align: center;
}

.skyleechTitle {
    color: transparent;
    font-family: "Russo One", sans-serif;
    text-transform: uppercase;
    font-size: min(7vw, 80px);
    background: linear-gradient( cornflowerblue 30%, white, darkmagenta 60%, cornflowerblue 100% );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-stroke: 1px silver;
    text-stroke: 1px silver;
    filter: drop-shadow(5px 5px 1px black) drop-shadow(1px 1px 1px magenta);
    padding-top: 20px;
}

.skyleechText {
    color: pink;
    font-family: "Yellowtail", cursive;
    font-size: min(6vw, 50px);    
    transform: rotateZ(-5deg);
    text-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px black, 0 0 15px black;
    margin: 5px;
}

.test {

}

@keyframes move {
    to {
        background-position-y: 250px;
    }
}

@media screen and (max-width: 600px) {
    .skyleechText {
        font-size: 4vh;
    }
}

@media screen and (max-height: 400px) {
    .skyleechText {
        display: none;
    }
}


@media screen and (max-height: 500px) {
    .image {
        height: 100px;
    }
    .skyleechTitle {
        font-size: 45px;
    }
}



