a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


.sidebar {
    position: relative;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 14px 20px;
    background-color: #111;
    border: 8px solid;
    border-color: darkcyan cyan;
    margin-bottom: 10px;
}

    .sidebar::before {
        content: " ";
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        border: 8px solid;
        border-color: darkmagenta magenta;
    }

    .sidebar a {
        color: white;
        padding: 16px;
        text-decoration: none;
        border-radius: 30px; /* pixels */
        position: relative;
    }

        .sidebar a:hover {
            background-color: #575757;
        }

.active {
    background-image: repeating-linear-gradient(cyan, magenta );
}

[activeClassName="active"] {
    background-image: repeating-linear-gradient(cyan, magenta );
}

.togglerButton{
    background-color: white;
    margin-bottom: 10px;
}

.container {
    display: flex;
    margin: 1em;

}

@media (max-width: 600px) {
    .skyleechTitle {
        font-size: 10vw;
        padding-top: 40px;
    }

    .skyleechText {
        font-size: 8vw;
    }

    .image {
     /*   height: 20vh;
        overflow: hidden;*/
    }
}