.contentContainer {
    /*position: absolute;*/
    /*  top: calc(25vh + 46px);*/
    /*left: 246px;*/
    color: white;
}

.neonBorder {
    display: grid;
    position: relative;
    border: 8px solid;
    border-color: darkcyan cyan;
    margin-bottom: 10px;
}

    .neonBorder::before {
        content: " ";
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        border: 8px solid;
        border-color: darkmagenta magenta;
    }

.neonTable {
    margin-bottom: 0px;
}


@media (max-width: 600px) {
    /*.contentContainer {
        top: calc(20vh + 46px);
    }*/
}
